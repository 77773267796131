<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-21 17:37:36
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-21 18:13:11
 * @FilePath: /aurora-carton-web/src/views/statisticalReport/dataStatistics/components/sellOut.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bad-total board_box line3">
    <div class="board_title dp_f">
      <div class="title">报损统计</div>
    </div>
    <div class="content">
        <div class="top">
          <div class="num" v-increase="formData.outPrice"></div>
          <div class="text">报损金额</div>
        </div>
        <div class="bottom">
          <div class="num" v-increase="formData.outCount"></div>
          <div class="text">报损数量</div>
        </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
});
const { proxy } = getCurrentInstance();
const {formData} = toRefs(props);

</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.bad-total {
  background:#fff url("~@img/dataStatistics/badBg.png") right bottom no-repeat;
  background-size: 138px 118px;
  .content {
    margin-top: 40px;
    .top{
      .num{
        font-size: 28px;
        color: $fsColor;
        margin-right: 10px;
        @include textOverflow(100%);
        font-weight: 600;
      }
      .text{
        margin-top: 24px;
        font-size: 14px;
        color: $fsColor2;
        line-height: 1;
      }
    }
    .bottom{
      margin-top: 44px;
      .num{
        font-size: 24px;
        color: $fsColor;
        margin-right: 10px;
        @include textOverflow(100%);
        font-weight: 600;
      }
      .text{
        margin-top: 24px;
        font-size: 14px;
        color: $fsColor2;
        line-height: 1;
      }
    }
  }
}
</style>
