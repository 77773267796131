import request from '../../http'
import {managerUrl} from '../../api'

//库存列表接口
export function insStockList(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/list",
    method:'post',
    data
  })
}

//库存详情接口
export function stockDetail(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/detail",
    method:'post',
    data
  })
}

//库存信息
export function stockInfo(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/info",
    method:'post',
    data
  })
}

//根据产品获取供应商那个
export function stockSupplier(data){
  return request({
    url:managerUrl + "/carton/product/supplier/product",
    method:'post',
    data
  })
}

//库存预警列表
export function stockAlarmList(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/list",
    method:'post',
    data
  })
}

//预警查看
export function stockAlarmLook(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/look",
    method:'post',
    data
  })
}

//预警详情
export function stockAlarmDetail(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/detail",
    method:'post',
    data
  })
}

//库存规则列表
export function stockRuleList(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/rule",
    method:'post',
    data
  })
}

//库存规则保存
export function stockRuleSave(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/rule/save",
    method:'post',
    data
  })
}

//库存规则复制
export function stockRuleCopy(data) {
  return request({
    url: managerUrl + "/carton/stock/alarm/rule/copy",
    method: 'post',
    data
  })
}

//效期预警列表
export function stockAlarmTime(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/list/time",
    method:'post',
    data
  })
}

//滞销预警列表
export function stockAlarmUnsalable(data){
  return request({
    url:managerUrl + "/carton/stock/alarm/list/unsalable",
    method:'post',
    data
  })
}

//盘点单列表
export function inventoryList(data){
  return request({
    url:managerUrl + "/carton/inventory/list",
    method: 'post',
    data
  })
}

//盘点单完成列表
export function inventoryComplete(data){
  return request({
    url:managerUrl + "/carton/inventory/list/complete",
    method:'get',
    data
  })
}

//添加盘点单未选择列表
export function inventoryAddGoodsList(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/list",
    method:'post',
    data
  })
}

//添加盘点单已选择列表
export function inventoryAddGoodsListSelect(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/list/selected",
    method:'post',
    data
  })
}

//添加已选择商品
export function inventoryAddGoods(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/add",
    method:'post',
    data
  })
}

//编辑添加已选择商品
export function inventoryAddEditGoods(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/add/edit",
    method:'post',
    data
  })
}

//全选商品
export function inventoryAddGoodsAll(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/add/all",
    method:'post',
    data
  })
}

//编辑全选商品
export function inventoryAddEditGoodsAll(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/add/all/edit",
    method:'post',
    data
  })
}

//全部删除商品
export function inventoryDelGoodsAll(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/remove/all",
    method:'post',
    data
  })
}

//编辑删除全部商品
export function inventoryDelEditGoodsAll(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/remove/all/edit",
    method:'post',
    data
  })
}

//编辑移除商品
export function inventoryDelEditGoods(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/remove/edit",
    method:'post',
    data
  })
}


//删除已选择商品
export function inventoryDelGoods(data){
  return request({
    url:managerUrl + "/carton/inventory/goods/remove",
    method:'post',
    data
  })
}

//新增盘点单
export function inventoryAdd(data){
  return request({
    url:managerUrl + "/carton/inventory/add",
    method:'post',
    data
  })
}

//编辑盘点计划
export function inventoryEdit(data){
  return request({
    url:managerUrl + "/carton/inventory/edit",
    method:'post',
    data
  })
}

//盘点计划详情
export function inventoryDetail(data){
  return request({
    url:managerUrl + "/carton/inventory/detail",
    method:'get',
    data
  })
}

//删除盘点计划
export function inventoryDel(data){
  return request({
    url:managerUrl + "/carton/inventory/del",
    method:'get',
    data
  })
}

//盘点单取消
export function inventoryCancel(data){
  return request({
    url:managerUrl + "/carton/inventory/cancel",
    method:'get',
    data
  })
}

//盘点完成商品列表
export function inventoryCompleteGoods(data) {
    return request({
        url: managerUrl + "/carton/inventory/complete/goods",
        method: 'post',
        data
    })
}

//盘点单标签
export function inventoryGoodsLabel(data) {
    return request({
        url: managerUrl + "/carton/inventory/goods/labels",
        method: 'post',
        data
    })
}


//盘点异常标签列表
export function inventoryAbnormalLabel(data) {
    return request({
        url: managerUrl + "/carton/inventory/complete/exception/labels",
        method: 'get',
        data
    })
}


//库存箱单关系列表
export function stockPackingList(data) {
  return request({
    url: managerUrl + "/carton/product/stock/product/packing",
    method: 'post',
    data
  })
}


//库存列表按标签导出
export function stockExportSerial(data) {
    return request({
        url: managerUrl + "/carton/product/stock/product/label/list",
        method: 'post',
        data
    })
}