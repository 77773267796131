<template>
  <div class="buy-total board_box line3">
    <div class="board_title dp_f">
      <div class="title">销售统计</div>
    </div>
    <div class="content">
      <div class="data-statistics-top">
        <div class="data-statistics-top-num">
          <div class="top-num" v-increase="formData.outPrice"></div>
          <div class="top-text">销售金额</div>
        </div>
        <img src="@img/dataStatistics/sell.png" alt="位元灵感" class="pic"/>
      </div>
      <div class="data-statistics-bottom">
        <div class="data-statistics-bottom-num">
          <div class="bottom-num" v-increase="formData.outCount"></div>
          <div class="bottom-text">销售数量</div>
        </div>
        <div class="data-statistics-bottom-num">
          <div class="bottom-num" v-increase="formData.inCount"></div>
          <div class="bottom-text">销退数量</div>
        </div>
        <div class="data-statistics-bottom-num">
          <div class="bottom-num" v-increase="formData.inPrice"></div>
          <div class="bottom-text">销退金额</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  formData: {
    type: Object,
    default: () => {
    },
  },
});
const {proxy} = getCurrentInstance();
const {formData} = toRefs(props);
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
