<template>
  <div class="sellBoard" v-loading="loading">
    <div class="queryBox dp_f">
        <div class="board-query dp_f">
          <el-select
            placeholder="请选择仓库"
            class="no-boder-select"
            v-model="formData.stockId"
            @change="getData"
          >
            <el-option
              v-for="item in sotckList"
              :key="item.stockId"
              :label="item.stockName"
              :value="item.stockId"
            />
          </el-select>
          <div class="timeSelect">
            <div
                class="item cur_p"
                :class="tabChecked === 2 ? 'active' : ''"
                @click="tabCheck(2)"
            >
              本年度
            </div>
            <div
                class="item cur_p"
                :class="tabChecked === 1 ? 'active' : ''"
                @click="tabCheck(1)"
            >
              本季度
            </div>
            <div
              class="item cur_p"
              :class="tabChecked === 0 ? 'active' : ''"
              @click="tabCheck(0)"
            >
              本月
            </div>
        </div>
      </div>
         <div class="btnBox dp_f">
          <el-date-picker
            v-model="formData.time"
            :type="timeType"
            unlink-panels
            class="w280 mr_16"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="large"
            :disabled-date="isOverToday"
            @change="changeTime"
            :clearable="false"
          />
           <export-btn type="census" :list-length="listLength" :form-data="formData"></export-btn>
        </div>
    </div>
    <div class="boardContent">
      <component
        :is="item.component"
        v-model:formData="item.formData"
        v-for="item in tabList"
        :key="item.timer"
      ></component>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, onMounted, reactive, ref, shallowRef, toRefs} from "vue";
import moment from "moment";
import {stockList} from "@/utils/api/procurement/stock.js";
import buyTotal from "./components/buyTotal.vue";
import outTotalVue from './components/outTotal.vue';
import sellOutVue from './components/sellOut.vue';
import allotVue from './components/allot.vue';
import badVue from './components/bad.vue';
import otherVue from './components/other.vue';
import ExportBtn from "@components/exportBtn/index.vue";
import TakeStock from "@views/statisticalReport/dataStatistics/components/takeStock.vue";
import {reportWebCensus} from "@utils/api/census.js"
import {isOverToday} from "@utils/tool";

const listLength = ref(0)

function setFormData() {
  return reactive({
    inCount: 0,
    inPrice: 0,
    outCount: 0,
    outPrice: 0,
    trueInPrice: 0,
    trueOutPrice: 0,
  })
}

const {proxy} = getCurrentInstance();
const loading = ref(false);
const state = reactive({
  tabChecked: -1,
  formData: {
    status: 0,
    time: [],
    stockId: "",
  },
  sotckList: [],
  timeType: 'daterange',
});
const {formData, tabChecked, sotckList, timeType} = toRefs(state);
//获取仓库列表
const getStockListData = async () => {
  const res = await stockList();
  if (res.code === 0) {
    if (res.data?.list?.length === 1) {
      formData.value.stockId = res.data.list[0].stockId;
      sotckList.value = res.data.list;
    } else if (res.data?.list?.length && res.data?.list?.length > 1) {
      sotckList.value.push({stockId: "", stockName: "全部仓库"});
      sotckList.value.push(...res.data.list);
    }
  } else {
    proxy.$message.error(res.msg);
  }
};
//切换本月年度
const tabCheck = (num) => {
  if (num === tabChecked.value) return;
  switch (num) {
    case 0:
      formData.value.time = [
        moment().startOf("months").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    case 1:
      formData.value.time = [
        moment().startOf("quarters").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    case 2:
      formData.value.time = [
        moment().startOf("year"),
        moment().format("YYYY-MM-DD"),
      ];
      break;
    default:
      break;
  }
  tabChecked.value = num;
  getData()
};

const changeTime = (e) => {
  tabChecked.value = -1;
  formData.value.time = [
    moment(e[0]).format("YYYY-MM-DD"),
    moment(e[1]).format("YYYY-MM-DD"),
  ];
  getData()
};
const tabList = ref([
  {
    key: 'total',
    component: shallowRef(outTotalVue),
    timer: 0,
    formData: setFormData()
  },
  {
    key: 'buy',
    component: shallowRef(buyTotal),
    timer: 0,
    formData: setFormData()
  },
  {
    key: "inventory",
    component: shallowRef(TakeStock),
    timer: 0,
    formData: setFormData()
  },
  {
    key: "loss",
    component: shallowRef(badVue),
    timer: 0,
    formData: setFormData()
  },
  {
    key: "sale",
    component: shallowRef(sellOutVue),
    timer: 0,
    formData: setFormData()
  },
  {
    key: "transfer",
    component: shallowRef(allotVue),
    timer: 0,
    formData: setFormData()
  },
  {
    key: "other",
    component: shallowRef(otherVue),
    timer: 0,
    formData: setFormData()
  },
]);

function getData() {
  listLength.value = 0
  let obj = {
    beginTime: formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).format('YYYYMMDD') : 0,
    endTime: formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).format('YYYYMMDD') : 0,
    stockId: formData.value.stockId || 0,
  }
  reportWebCensus(obj).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    tabList.value.forEach(item => {
      item.formData.inCount = res.data?.[item.key]?.inCount || 0;
      item.formData.outCount = res.data?.[item.key]?.outCount || 0;
      item.formData.inPrice = proxy.$filters.toYuan(res.data?.[item.key]?.inPrice);
      item.formData.trueInPrice = res.data?.[item.key]?.inPrice || 0;
      item.formData.outPrice = proxy.$filters.toYuan(res.data?.[item.key]?.outPrice);
      item.formData.trueOutPrice = res.data?.[item.key]?.outPrice || 0;
      //判断item.formData里面的每一个值是否为0
      for (let key in item.formData) {
        if (key === 'inPrice' || key === 'outPrice') continue
        if (item.formData[key] !== 0 && item.formData[key] !== '0.00') {
          listLength.value = listLength.value + 1
        }
      }
    })
  })
}

onMounted(() => {
  tabCheck(0)
  getStockListData();
  getData()
});

</script>

<script>


export default {
  name: "sellBoard",

};
</script>

<style lang="scss" scoped src="./index.scss">
</style>
<style lang="scss" scoped >
.select {
  margin: 0 8px;
  width: 160px;
}
</style>