<template>
  <div class="store-total board_box">
    <div class="board_title dp_f">
      <div class="title">出入库统计<span class="sub-title">（包含所有出入库类型）</span></div>
    </div>
    <div class="content">
      <div class="total-info dp_f">
        <div class="total-single">
          <img src="@img/dataStatistics/outStore.png" alt="位元灵感" class="pic"/>
          <div class="total-info-price">
            <div class="num" v-increase="formData.outPrice"></div>
            <div class="text">出库金额</div>
          </div>
        </div>
        <div class="total-single">
          <img src="@img/dataStatistics/outCount.png" alt="位元灵感" class="pic"/>
          <div class="total-info-price">
            <div class="num" v-increase="formData.outCount"></div>
            <div class="text">出库数量</div>
            </div>
        </div>
        <div class="total-single">
          <img src="@img/dataStatistics/inStore.png" alt="位元灵感" class="pic"/>
          <div class="total-info-price">
            <div class="num" v-increase="formData.inPrice"></div>
            <div class="text">入库金额</div>
          </div>
        </div>
        <div class="total-single">
          <img src="@img/dataStatistics/inCount.png" alt="位元灵感" class="pic"/>
          <div class="total-info-price">
            <div class="num" v-increase="formData.inCount"></div>
            <div class="text">入库数量</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
});
const { proxy } = getCurrentInstance();
const {formData} = toRefs(props);
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

</style>
