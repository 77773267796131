import request from '@utils/http'

//数据统计
export function reportWebCensus(data) {
    return request({
        url: "/carton/report/web/census",
        method: 'post',
        data
    })
}

//销售毛利统计
export function reportWebProfit(data) {
    return request({
        url: "/carton/report/web/sale/profit",
        method: 'post',
        data
    })
}